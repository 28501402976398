// Font sizes
.font-12 {
	font-size: 12px !important;
}

.planning-board-wrap .col.border {
    border-color: #E3E3E3 !important;
}

.planning-filter-belt {
    background-color: #f7f8f9;
    border-radius: 5px;

    label {
        padding: 10px;
        margin-bottom: 0;

        .el-checkbox__input {
            border-radius: 2px;
            
            .el-checkbox__inner {
                background-color: #fff;
                border-color: $roch-dark-blue !important;

                &:after {
                    border-color: $roch-dark-blue !important;
                }
            }
        }

        .el-checkbox__label {
            margin-right: 10px;
            color: $roch-dark-blue;
            font-size: 12px;
        }

        &.is-checked {
            .el-checkbox__input {
                border-radius: 2px;
                
                .el-checkbox__inner {
                    background-color: #fff;
                    border-color: $roch-dark-blue !important;
    
                    &:after {
                        border-color: $roch-dark-blue !important;
                    }
                }
            }

            .el-checkbox__label {
                font-weight: 700;
                color: $roch-dark-blue;
            }
        }
    }
}

.planning-board-job-card {
    border-radius: 5px;
    font-size: 10px;
    p {
        color: $roch-dark-blue;
    }

    .pbjc-notes,
    .pbjc-subjobs {
        i {
            height: 15px;
            width: 15px;
            color: #b1b1b1;
        }
        p {
            font-size: 10px;
        }
    }
}

.planning-board-card {
    border: none !important;
    border-radius: 5px;
    &-is-waiting {
        background-color: #ffaf7a;
        
        .sub-jobs-box {
            background-color: #fcfaff;
        }
    }
    &-flexible {
        background-color: #e0cdfe;
        
        .sub-jobs-box {
            background-color: #fcfaff;
        }
    }

    &-unassigned {
        background-color: #e4e7ed;
        
        .sub-jobs-box {
            background-color: #fcfaff;
        }
    }

    &-unassigned-painter {
        background-color: #f1e8ff;
        
        .sub-jobs-box {
            background-color: #fcfaff;
        }
    }

    &-all-assigned-painter {
        background-color: #e2eaff;

        .sub-jobs-box {
            background-color: #f7f9ff;
        }
    }
    &-all-assigned-sent {
        background-color: #a3a4a7;
        
        .sub-jobs-box {
            background-color: #fcfaff;
        }
    }
    &-all-assigned-sent-painter {
        background-color: #b4c6f5;

        .sub-jobs-box {
            background-color: #f7f9ff;
        }
    }
    &-all-assigned {
        background-color: #c0c4cc;

        .sub-jobs-box {
            background-color: #f7f9ff;
        }
    }
}


.sub-jobs-box {
    padding: 13px 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.pboard-sub-job-card {
    &:not(:last-child) {
        padding-bottom: 15px;
    }

    p {
        color: $roch-dark-blue;
    }
}


.planning-board-avail-contractors {
    padding: 3px;
    border-color: #e3e3e3 !important;
}

.contractor-card {
    padding: 5px;
    cursor: move;
    background-color: #fff;
    border: 1px solid #e3e3e3;
    border-radius: 5px;
}

.pboard-job-popover {
    margin: -12px; //bigbrain overrides
    padding: 15px;
    background-color: #f7f8f9;
    border-radius: 5px;

    .el-table {
        background-color: #f7f8f9 !important;

        thead {
            th {
                padding-top: 0;
                padding-bottom: 0;
                background-color: #f7f8f9 !important;
                border-bottom: none !important;
            }

            .cell {
                font-size: 12px !important;
                font-weight: 400 !important;
                color: #b1b1b1 !important;
            }
        }

        tbody {
            td {
                padding-top: 0;
                padding-bottom: 0;
                border-bottom: none !important;
                background-color: #f7f8f9 !important;
            }

            .cell {
                word-break: break-word !important;
                font-size: 12px !important;
                color: $roch-dark-blue !important;
                font-weight: 400 !important;
            }
        }
    }
}

.job-detail-modal-header {
    border-bottom: none !important;
}