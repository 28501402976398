
.btn-accept	{
	background-color: rgba(129,190, 55, 1);
	color: white !important;
}

.btn-decline {
	color: grey;
	&:hover
	{
		background-color: red;
		color:white !important;
	}
}

.btn-comment {
	color: grey;
}

.btn-cancel {
	color: #fff;
	background-color: #8d8d8d;
	border-color: #8d8d8d;
}