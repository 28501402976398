#progress-report-app-page {
  background-color: #f7f8f9;

  .progress-report-page {
    color: #000000;
    font-family: "lateral";
    font-size: 14px;

    .progress-report-header {
      color: #102945;
      font-family: "lateral";
      font-size: 14px;
      line-height: 28px;

      h2 {
        font-size: 28px;
        font-family: "lateral";
        font-weight: 700;
        line-height: 36px;
        color: #102945;
      }

      .svg,
      a {
        color: #102945;
      }
    }

    .img-fluid {
      width: 120px;
      height: 85px;
      object-fit: cover;
      padding: 5px;
    }
  }
}
