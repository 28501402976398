@font-face {
  font-family: "Aspekta";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Aspekta-400.otf");
}

@font-face {
  font-family: "Aspekta";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/Aspekta-700.otf");
}

.font-aspekta-400 {
  font-family: "Aspekta", sans-serif;
  font-weight: 400;
}

.font-aspekta-700 {
  font-family: "Aspekta", sans-serif;
  font-weight: 700;
}

.colourScheduleLightbox {
  .attachmentDiv {
    width: 144px;
    border: 1px solid #d9d9d9;
    text-align: center;

    div {
      background-color: #f8f9fa;
      font-size: 12px;
    }

    &.image img {
      width: 100%;
      height: 76px;
      object-fit: cover;
      padding: 0px;
    }

    &.pdf {
      padding-top: 10px;

      div {
        margin-top: 10px;
      }
    }

    &:hover {
      cursor: pointer;
      border: 1px solid #ababab;

      div {
        background-color: #f3f4f5;
      }
    }
  }
}

.colour-schedule-show {
  font-family: "Aspekta", sans-serif;
  font-size: 16px;
  color: #505151;
  background-color: #f8f9fa;

  &.row {
    margin: 0px !important;
  }

  .intro-div {
    background-color: #fff;
  }

  .intro-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .img-cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .intro-left-div {
    margin-right: 40px;
    @media screen and (max-width: 960px) {
      margin-right: 16px !important;
    }

    @media screen and (min-width: 1300px) {
      margin-left: 100px !important;
    }
    @media screen and (min-width: 1400px) {
      margin-left: 100px !important;
    }
    @media screen and (min-width: 1500px) {
      margin-left: 150px !important;
    }
    @media screen and (min-width: 2000px) {
      margin-left: 200px !important;
    }
  }

  .intro-table td {
    vertical-align: top;
  }

  .proposal-div {
    background-color: #fff;
    max-width: 1200px;
    margin: auto;
  }

  .overlay-requested-div {
    background-color: #fff;
    width: 100%;
    padding-top: 40px;

    .overlay-requested-content {
      background-color: #f8f9fa;
      max-width: 1200px;
      margin: auto;
    }
  }

  .colourTable {
    .area {
      width: 300px;

      @media screen and (max-width: 767px) {
        width: 240px;
      }
    }
    .option {
      width: 200px;
    }
  }

  .bb-gray {
    border-bottom: 1px solid #d9d9d9;
  }

  .colour-proposal-header {
    display: flex;

    @media screen and (max-width: 599px) {
      display: none !important;
    }
  }

  .colour-proposal-body {
    font-family: "Aspekta", sans-serif;
    font-weight: 400;
    color: #505151;
  }

  .option-label {
    font-family: "Barlow", sans-serif;
    font-weight: 700;
    color: #707070;
    letter-spacing: 1px;
    margin-bottom: 5px;

    @media screen and (min-width: 599px) {
      &.topic {
        display: none !important;
      }
    }
  }

  .option-line-item-title {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #d9d9d9;

    @media screen and (max-width: 599px) {
      border-bottom: none;
    }
  }

  .one-option {
    @media screen and (max-width: 599px) {
      &.col-6 {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }

  .p-comment {
    font-size: 12px;
    font-family: "Aspekta", sans-serif;
    color: #707070 !important;
    padding-top: 5px;
  }

  .el-radio-group label.main-label {
    font-size: 16px;
    font-weight: 700;
    color: #505151;
  }

  .btn-submit {
    background-color: #1e242b;
    color: #fff;
    border-radius: 20px;
    width: 100%;
  }
  .btn-submit:hover {
    color: #fff;
    font-weight: 700;
  }

  .selectedColour {
    width: 72px;
    height: 72px;
    margin-right: 5px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .selectedColourDiv {
    background-color: #edf3fd;
  }

  .el-input__inner {
    border-radius: 0px;
  }

  .el-radio__input.is-checked + .el-radio__label {
    color: #2d3748;
  }

  .el-radio__label {
    font-size: 16px;
  }

  .overlayDiv {
    cursor: pointer;
    color: #ababab;
    font-weight: 700;
  }
  .overlayActive {
    font-weight: 700;
    border-bottom: 1px solid #1e242b;
    color: #1e242b;
  }
  .termsOverflow {
    border: 1px solid #dcdfe6;
    border-radius: 5px;
    height: 280px;
    overflow-y: auto;
    padding: 1rem;
  }
  h1,
  h2,
  h3 {
    font-family: "Barlow", sans-serif;
    color: #505151;
  }
  h1 {
    font-size: 48px;
  }
  h2 {
    font-size: 44px;
    font-weight: 500;
    line-height: 52px;
  }
  h3 {
    font-size: 30px;
    font-weight: 500;
    line-height: 36px;
    color: #505151;
  }

  .plabel {
    font-size: 14px;
    font-family: "Barlow", sans-serif;
    font-weight: 700;
    color: #707070;
    letter-spacing: 1px;
  }

  .table thead th,
  .table td {
    border-top: none;
    border-bottom: 1px solid #d9d9d9;
  }

  .color-gray {
    color: #505151;
  }

  th {
    font-family: "Barlow", sans-serif;
    font-weight: 700;
    color: #707070;
    letter-spacing: 1px;
  }

  .square-tile {
    img {
      width: 40px;
      height: 40px;
      object-fit: cover;
    }
  }

  .square-text {
    color: #505151;
    font-size: 14px;
    font-weight: 400;

    span {
      font-size: 12px;
      color: #707070;
    }
  }

  .consultant-avatar {
    width: 56px;
    height: 56px;
    border-radius: 50%;
  }

  .el-radio {
    font-family: "Aspekta", sans-serif;
    font-weight: 400;
  }

  .selectRadioGroup {
    display: flex;
    @media screen and (max-width: 599px) {
      display: inline-block;

      .bb-gray {
        border-bottom: none;
      }
    }
  }

  .imgicon {
    width: 144px;
  }

  .project_overlay {
    background-color: #f8f7f5;

    .price-text {
      font-family: "Barlow", sans-serif;
      font-weight: 700;
      font-size: 28px;
    }
    .price-gst {
      font-family: "Aspekta", sans-serif;
      font-size: 12px;
      font-weight: 400;
    }
  }
} // end of .colour-schedule-show

.colour-schedule-card-page {
  margin: 0px 50px;

  .text-ellipsis {
    color: #1976d2;
  }
}

.colour-schedule-form {
  font-family: "Roboto", sans-serif;
  font-size: 16px;

  h1 {
    font-size: 24px;
    font-family: "lateral", sans-serif;
    font-weight: 700;
    color: #102945;
  }

  h2 {
    color: #e53e3e;
    font-family: "lateral", sans-serif;
    font-weight: 400;
    font-size: 20px;
  }

  h3 {
    font-size: 24px;
    font-family: "lateral", sans-serif;
    font-weight: 400;
    color: #102945;
  }

  p {
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    color: #102945;
  }

  .line-item-table {
    th,
    td {
      border: 1px solid #d9d9d9;
    }

    th {
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      font-weight: 700;
      color: #102945;
    }
  }

  .btn {
    font-family: "lateral", sans-serif;
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    padding: 10px;

    &.btn-preview {
      background: #51a6d3;
    }

    &.btn-send {
      background: #102945;
    }
  }

  .el-input input {
    font-size: 14px !important;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    color: #1e242b;
    padding: 15px 20px 15px 5px;
  }

  .colour-box {
    width: 32px;
    height: 32px;
    margin-right: 5px;
    background-image: url("/img/interiors/swatch_default.png");
    position: relative;
  }

  .colour-trash {
    background-color: #d9d9d9;
    width: 32px;
    height: 32px;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 0;

    svg {
      color: #fff;
      margin-top: 35%;
    }
  }

  .comment {
    &.form-control {
      font-size: 14px !important;
      background-color: #f8f9fa;
      border: 1px solid #f8f9fa;
    }

    &::placeholder {
      color: #ababab;
    }
  }

  .secondUpload {
    .el-upload {
      display: none;
    }
  }

  .el-upload-dragger {
    width: 136px;
    height: 136px;
  }

  .el-upload--picture-card {
    width: 136px;
    height: 136px;
  }

  .colourScheduleLightbox {
    .attachmentDiv {
      width: 160px;
      height: 135px;
      border: 1px solid #d9d9d9;
      text-align: center;
      border-radius: 8px;

      &.image img {
        height: 115px;
      }

      div {
        border-radius: 0 0 8px 8px;
      }

      &.pdf {
        img {
          margin-top: 20px;
          margin-bottom: 19px;
        }
      }
    }

    .colour-trash {
      position: absolute;
      border-radius: 8px;
      background-color: #000;
      opacity: 0.6;
    }
  }

  .upload-loading {
    width: 160px;
    height: 135px;
    border: 1px solid #d9d9d9;
    text-align: center;
    border-radius: 8px;

    svg {
      margin-top: 30%;
      color: #1976d2;
    }
  }
}

.accepted_popup {
  h2 {
    font-size: 44px;
    font-weight: 500;
    line-height: 52px;
    font-family: "Barlow", sans-serif;
    color: #505151;
  }

  .accepted_body {
    padding: 10px;
  }

  p {
    font-family: "Aspekta", sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #505151;
  }

  .img-fluid {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.btn-colour-schedule {
  background-color: #1e242b;
  color: #fff;
  padding: 14px 40px;
  border-radius: 56px;
  font-family: "Aspekta", sans-serif;
  font-weight: 700;
  font-size: 16px;
  border: 0;
  box-shadow: none !important;

  &:hover {
    background-color: #1e242b;
    color: #fff;
  }

  &.disabled {
    background-color: #edf3fd;
    color: #505151;
  }
}

.colour-icon {
  width: 32px;
  height: 32px;
  object-fit: cover;
}
