.msgC-conversations_wrap p {
  font-family: "lateral" !important;
}

.message-centre_preview-card {
  cursor: pointer;
  padding: 1.5rem 1rem;
  border-bottom: 1px solid #e3e3e3;
  transition: background-color 0.15s ease-in-out;
}

.selected-conversation {
  background-color: #f1f4ff !important;
  border-right: 4px solid #6499ba;
}

.MC_chat-wrap .el-tabs__nav {
  margin-left: 1rem;
}

.MC_chat-wrap .el-tabs__nav .el-tabs__item {
  color: #536c79;
  font-weight: 700;
}

.MC_chat-wrap .el-tabs__nav .el-tabs__item.is-active {
  color: #201a56;
}

.MC_chat-wrap .el-tabs__nav .el-tabs__active-bar {
  height: 4px;
  background-color: #201a56;
}

// 27-11-2023 Weird class drilling to correctly grow inner chat window
.MC_chat-wrap .el-tabs__content {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.MC_chat-wrap .el-tabs__content .el-tab-pane {
  display: flex;
  flex-grow: 1;
}

.MC_chat-wrap .MC_chat-window-column {
  display: flex;
  flex-grow: 1;
}

// The Right column content of the chat-centre
.MC_chat-wrap .MC_chat-window-column .MC_chat-inner {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.MC_chat-wrap .MC_chat-window-column .MC_chat-inner > div {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.MC_chat-wrap .MC_chat-window-column .MC_chat-inner .chat-window-header {
  padding: 1rem;
  border-bottom: 1px solid #e3e3e3;
}

.MC_chat-wrap .MC_chat-window-column .MC_chat-inner .chat-window {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.MC_chat-wrap .chat-input {
  min-height: 64px;
  border: none !important;
  // border-top: 1px solid #E3E3E3;
  border-left: none;
  border-right: none;
  border-bottom: none;
}

.job-message_preview-card {
  cursor: pointer;
  padding: 1rem 0.75rem;
  border-bottom: 1px solid #e3e3e3;
  transition: background-color 0.15s ease-in-out;
}

.job-message-tab {
  padding: 0px !important;
}
.job-chat-input {
  min-height: 64px;
  border: none !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: none !important;
}

.read-only-badge {
  font-weight: 500;
  font-family: "lateral" !important;
  font-size: 11px;
  color: rgb(84, 84, 84);
  width: 75px;
  opacity: 0.75;
}
