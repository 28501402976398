

.timeline-card {
  background-color: #fff;
}

.feedback-face-btn {
  cursor: pointer;
  position: relative;
  height: 131px;
  width: 126px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  margin: 0 5px;
  padding: 20px;
  border: 1px solid $border-grey;
  border-radius: 5px;

  @media (max-width: 575.98px) {
    padding: 5px;

    .fa-4x {
      font-size: 3em;
    }
  }

  @media (max-width: 1199.98px) {
    margin-bottom: 15px;
    width: 100%;
    height: 100%;
  }

  // larger than 992px
  @media (min-width: 992px) {
    width: 101px;
  }

  // larger than 1200px
  @media (min-width: 1200px) {
    width: 126px;
  }

  @media (min-width: 992px) {

  }

  img {
    margin: 0 auto 20px;
  }

  [type=radio]:checked~p {
    color: $roch-light-blue !important;
  }

  [type=radio]:checked~.active-decoration {
    position: absolute;
    top: 0;
    left: 0;
    height: 131px;
    width: 95px;
    border: 1px solid $roch-light-blue;
    border-radius: 5px;
    box-shadow: $card-shadow;

    @media (max-width: 1199.98px) {
      width: 100%;
      height: 100%;
    }

    // larger than 992px
    @media (min-width: 992px) {
      width: 100%;
    }

    // larger than 1200px
    @media (min-width: 1200px) {
      width: 100%;
    }
  }

  .far {
    color: #b1b1b4;
  }

  [type=radio]:checked~.far {
    color: $roch-light-blue;
  }
}
