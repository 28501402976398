@import "~@fortawesome/fontawesome-pro/css/all.css";
@font-face {
  font-family: "lateral";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/Lateral-StandardRegular.otf");
}
@font-face {
  font-family: "lateral";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/Lateral-StandardBold.otf");
}
.font-lateral {
  font-family: "lateral" !important;
}

.btn-accept {
  background-color: rgb(129, 190, 55);
  color: white !important;
}

.btn-decline {
  color: grey;
}
.btn-decline:hover {
  background-color: red;
  color: white !important;
}

.btn-comment {
  color: grey;
}

.btn-cancel {
  color: #fff;
  background-color: #8d8d8d;
  border-color: #8d8d8d;
}

h1 {
  font-size: 2.1875rem;
}
@media (max-width: 575.98px) {
  h1 {
    font-size: 28px;
  }
}

.text-red,
.red-text {
  color: red;
}
.text-red strong,
.red-text strong {
  color: red;
}

.text-black,
.font-black {
  color: #000;
}
.text-black strong,
.font-black strong {
  color: #000;
}

.text-dark-blue {
  color: #102945;
}
.text-dark-blue strong {
  color: #102945;
}

.text-com-blue {
  color: #024677;
}
.text-com-blue strong {
  color: #024677;
}

.color-placeholder {
  color: #888888;
}

.color-dash-blue {
  color: #20a8d8;
}

.p-bold,
.fw-700 {
  font-weight: bold;
}

.p-thin,
.fw-400 {
  font-weight: 400;
}

.font-12 {
  font-size: 12px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-20 {
  font-size: 20px !important;
}

.p-small {
  font-size: 0.95em;
}

.hover-underline:hover {
  text-decoration: underline;
}

.open-sans {
  font-family: "Open Sans", sans-serif;
}

.desc-text {
  color: #677781;
}

.bg-white {
  background-color: #fff;
}

.bg-body {
  background-color: #f7f8f9;
}

.bg-dark-blue {
  background-color: #102945;
}

.bg-com-blue {
  background-color: #024677;
}

.full-bg {
  background-size: cover;
  background-position: 50%;
}

.bg-chat-mine {
  background-color: #e8edf4;
}

.bg-chat-yours {
  background-color: #f8f8f8;
}

.bg-secondary-50 {
  background-color: #e9f5fc;
}

.full-bg-img {
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}

.cursor-pointer {
  cursor: pointer;
}

.tag-email {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  min-height: 38px !important;
  color: #102945 !important;
  font-size: 14px !important;
  background-color: #f4fafd !important;
  border: 1px solid #96cae5 !important;
  border-radius: 5px !important;
}

.timeline-card {
  background-color: #fff;
}

.feedback-face-btn {
  cursor: pointer;
  position: relative;
  height: 131px;
  width: 126px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  margin: 0 5px;
  padding: 20px;
  border: 1px solid #b1b1b4;
  border-radius: 5px;
}
@media (max-width: 575.98px) {
  .feedback-face-btn {
    padding: 5px;
  }
  .feedback-face-btn .fa-4x {
    font-size: 3em;
  }
}
@media (max-width: 1199.98px) {
  .feedback-face-btn {
    margin-bottom: 15px;
    width: 100%;
    height: 100%;
  }
}
@media (min-width: 992px) {
  .feedback-face-btn {
    width: 101px;
  }
}
@media (min-width: 1200px) {
  .feedback-face-btn {
    width: 126px;
  }
}
.feedback-face-btn img {
  margin: 0 auto 20px;
}
.feedback-face-btn [type=radio]:checked ~ p {
  color: #3393c9 !important;
}
.feedback-face-btn [type=radio]:checked ~ .active-decoration {
  position: absolute;
  top: 0;
  left: 0;
  height: 131px;
  width: 95px;
  border: 1px solid #3393c9;
  border-radius: 5px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
}
@media (max-width: 1199.98px) {
  .feedback-face-btn [type=radio]:checked ~ .active-decoration {
    width: 100%;
    height: 100%;
  }
}
@media (min-width: 992px) {
  .feedback-face-btn [type=radio]:checked ~ .active-decoration {
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .feedback-face-btn [type=radio]:checked ~ .active-decoration {
    width: 100%;
  }
}
.feedback-face-btn .far {
  color: #b1b1b4;
}
.feedback-face-btn [type=radio]:checked ~ .far {
  color: #3393c9;
}

.font-12 {
  font-size: 12px !important;
}

.planning-board-wrap .col.border {
  border-color: #E3E3E3 !important;
}

.planning-filter-belt {
  background-color: #f7f8f9;
  border-radius: 5px;
}
.planning-filter-belt label {
  padding: 10px;
  margin-bottom: 0;
}
.planning-filter-belt label .el-checkbox__input {
  border-radius: 2px;
}
.planning-filter-belt label .el-checkbox__input .el-checkbox__inner {
  background-color: #fff;
  border-color: #102945 !important;
}
.planning-filter-belt label .el-checkbox__input .el-checkbox__inner:after {
  border-color: #102945 !important;
}
.planning-filter-belt label .el-checkbox__label {
  margin-right: 10px;
  color: #102945;
  font-size: 12px;
}
.planning-filter-belt label.is-checked .el-checkbox__input {
  border-radius: 2px;
}
.planning-filter-belt label.is-checked .el-checkbox__input .el-checkbox__inner {
  background-color: #fff;
  border-color: #102945 !important;
}
.planning-filter-belt label.is-checked .el-checkbox__input .el-checkbox__inner:after {
  border-color: #102945 !important;
}
.planning-filter-belt label.is-checked .el-checkbox__label {
  font-weight: 700;
  color: #102945;
}

.planning-board-job-card {
  border-radius: 5px;
  font-size: 10px;
}
.planning-board-job-card p {
  color: #102945;
}
.planning-board-job-card .pbjc-notes i,
.planning-board-job-card .pbjc-subjobs i {
  height: 15px;
  width: 15px;
  color: #b1b1b1;
}
.planning-board-job-card .pbjc-notes p,
.planning-board-job-card .pbjc-subjobs p {
  font-size: 10px;
}

.planning-board-card {
  border: none !important;
  border-radius: 5px;
}
.planning-board-card-is-waiting {
  background-color: #ffaf7a;
}
.planning-board-card-is-waiting .sub-jobs-box {
  background-color: #fcfaff;
}
.planning-board-card-flexible {
  background-color: #e0cdfe;
}
.planning-board-card-flexible .sub-jobs-box {
  background-color: #fcfaff;
}
.planning-board-card-unassigned {
  background-color: #e4e7ed;
}
.planning-board-card-unassigned .sub-jobs-box {
  background-color: #fcfaff;
}
.planning-board-card-unassigned-painter {
  background-color: #f1e8ff;
}
.planning-board-card-unassigned-painter .sub-jobs-box {
  background-color: #fcfaff;
}
.planning-board-card-all-assigned-painter {
  background-color: #e2eaff;
}
.planning-board-card-all-assigned-painter .sub-jobs-box {
  background-color: #f7f9ff;
}
.planning-board-card-all-assigned-sent {
  background-color: #a3a4a7;
}
.planning-board-card-all-assigned-sent .sub-jobs-box {
  background-color: #fcfaff;
}
.planning-board-card-all-assigned-sent-painter {
  background-color: #b4c6f5;
}
.planning-board-card-all-assigned-sent-painter .sub-jobs-box {
  background-color: #f7f9ff;
}
.planning-board-card-all-assigned {
  background-color: #c0c4cc;
}
.planning-board-card-all-assigned .sub-jobs-box {
  background-color: #f7f9ff;
}

.sub-jobs-box {
  padding: 13px 10px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.pboard-sub-job-card:not(:last-child) {
  padding-bottom: 15px;
}
.pboard-sub-job-card p {
  color: #102945;
}

.planning-board-avail-contractors {
  padding: 3px;
  border-color: #e3e3e3 !important;
}

.contractor-card {
  padding: 5px;
  cursor: move;
  background-color: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
}

.pboard-job-popover {
  margin: -12px;
  padding: 15px;
  background-color: #f7f8f9;
  border-radius: 5px;
}
.pboard-job-popover .el-table {
  background-color: #f7f8f9 !important;
}
.pboard-job-popover .el-table thead th {
  padding-top: 0;
  padding-bottom: 0;
  background-color: #f7f8f9 !important;
  border-bottom: none !important;
}
.pboard-job-popover .el-table thead .cell {
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #b1b1b1 !important;
}
.pboard-job-popover .el-table tbody td {
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: none !important;
  background-color: #f7f8f9 !important;
}
.pboard-job-popover .el-table tbody .cell {
  word-break: break-word !important;
  font-size: 12px !important;
  color: #102945 !important;
  font-weight: 400 !important;
}

.job-detail-modal-header {
  border-bottom: none !important;
}

.feedback-face-btn-no-border {
  cursor: pointer;
  position: relative;
  height: 95px;
  width: 95px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 5px;
  padding: 20px;
  color: #e0e0e0;
}
.feedback-face-btn-no-border .far {
  color: #e0e0e0 !important;
}
@media (max-width: 575.98px) {
  .feedback-face-btn-no-border {
    padding: 5px;
  }
  .feedback-face-btn-no-border .fa-4x {
    font-size: 3em;
  }
}
@media (max-width: 1199.98px) {
  .feedback-face-btn-no-border {
    margin-bottom: 15px;
    width: 100%;
    height: 100%;
  }
}

.give-feedback-wrap textarea {
  border-radius: 5px;
}

.feedback-face-frown, .active-face-frown .feedback-face-frown, .feedback-face-meh, .active-face-meh .feedback-face-meh, .feedback-face-smile, .active-face-smile .feedback-face-smile {
  height: 50px;
  width: 50px;
}

.feedback-face-frown::after, .active-face-frown .feedback-face-frown::after, .feedback-face-meh::after, .active-face-meh .feedback-face-meh::after, .feedback-face-smile::after, .active-face-smile .feedback-face-smile::after {
  height: 50px;
  font-size: 50px;
  line-height: 50px;
  position: absolute;
  width: 50px;
  transition: 0.3s all ease-in-out;
}

.feedback-face-smile:hover::after, .active-face-smile .feedback-face-smile::after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f118";
  color: #7fbf7f;
}

.feedback-face-meh:hover::after, .active-face-meh .feedback-face-meh::after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f11a";
  color: #ec9a5f;
}

.feedback-face-frown:hover::after, .active-face-frown .feedback-face-frown::after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f119";
  color: #e34735;
}

.feedback-face-smile::after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f118";
}
.feedback-face-meh::after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f11a";
}
.feedback-face-frown::after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f119";
}
.job-card-page h3 {
  font-size: 18px;
}

@media (max-width: 1263px) {
  .subjob-card .col-12 {
    padding-bottom: 0 !important;
  }
}

.subjob-titles {
  display: flex !important;
}
@media (max-width: 1263px) {
  .subjob-titles {
    display: none !important;
  }
}

.radio-group-wrap {
  min-width: 180px;
}
@media (max-width: 750px) {
  .radio-group-wrap {
    margin-top: 15px;
    width: 100%;
  }
  .radio-group-wrap label {
    width: 50%;
  }
}
.radio-group-wrap label {
  min-width: 90px;
}
.radio-group-wrap label span {
  width: 100%;
}
.radio-group-wrap .el-radio-button {
  color: #20a8d8;
}
.radio-group-wrap .el-radio-button:first-child .el-radio-button__inner {
  border-radius: 5px 0 0 5px;
}
.radio-group-wrap .el-radio-button:last-child .el-radio-button__inner {
  border-radius: 0 5px 5px 0;
}
.radio-group-wrap .el-radio-button__inner {
  color: #20a8d8;
  border-color: #20a8d8 !important;
}
.radio-group-wrap .el-radio-button__inner.is-active {
  color: #fff;
}

.subjob-wrap {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.subjob-share-modal .modal-body {
  padding-top: 0;
}

.btn-add-email {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  min-height: 38px !important;
  color: #102945 !important;
  font-size: 14px !important;
  background-color: #f4fafd !important;
  border: 1px solid #96cae5 !important;
  border-radius: 5px !important;
}

.msgC-conversations_wrap p {
  font-family: "lateral" !important;
}

.message-centre_preview-card {
  cursor: pointer;
  padding: 1.5rem 1rem;
  border-bottom: 1px solid #e3e3e3;
  transition: background-color 0.15s ease-in-out;
}

.selected-conversation {
  background-color: #f1f4ff !important;
  border-right: 4px solid #6499ba;
}

.MC_chat-wrap .el-tabs__nav {
  margin-left: 1rem;
}

.MC_chat-wrap .el-tabs__nav .el-tabs__item {
  color: #536c79;
  font-weight: 700;
}

.MC_chat-wrap .el-tabs__nav .el-tabs__item.is-active {
  color: #201a56;
}

.MC_chat-wrap .el-tabs__nav .el-tabs__active-bar {
  height: 4px;
  background-color: #201a56;
}

.MC_chat-wrap .el-tabs__content {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.MC_chat-wrap .el-tabs__content .el-tab-pane {
  display: flex;
  flex-grow: 1;
}

.MC_chat-wrap .MC_chat-window-column {
  display: flex;
  flex-grow: 1;
}

.MC_chat-wrap .MC_chat-window-column .MC_chat-inner {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.MC_chat-wrap .MC_chat-window-column .MC_chat-inner > div {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.MC_chat-wrap .MC_chat-window-column .MC_chat-inner .chat-window-header {
  padding: 1rem;
  border-bottom: 1px solid #e3e3e3;
}

.MC_chat-wrap .MC_chat-window-column .MC_chat-inner .chat-window {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.MC_chat-wrap .chat-input {
  min-height: 64px;
  border: none !important;
  border-left: none;
  border-right: none;
  border-bottom: none;
}

.job-message_preview-card {
  cursor: pointer;
  padding: 1rem 0.75rem;
  border-bottom: 1px solid #e3e3e3;
  transition: background-color 0.15s ease-in-out;
}

.job-message-tab {
  padding: 0px !important;
}

.job-chat-input {
  min-height: 64px;
  border: none !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: none !important;
}

.read-only-badge {
  font-weight: 500;
  font-family: "lateral" !important;
  font-size: 11px;
  color: rgb(84, 84, 84);
  width: 75px;
  opacity: 0.75;
}

#progress-report-app-page {
  background-color: #f7f8f9;
}
#progress-report-app-page .progress-report-page {
  color: #000000;
  font-family: "lateral";
  font-size: 14px;
}
#progress-report-app-page .progress-report-page .progress-report-header {
  color: #102945;
  font-family: "lateral";
  font-size: 14px;
  line-height: 28px;
}
#progress-report-app-page .progress-report-page .progress-report-header h2 {
  font-size: 28px;
  font-family: "lateral";
  font-weight: 700;
  line-height: 36px;
  color: #102945;
}
#progress-report-app-page .progress-report-page .progress-report-header .svg,
#progress-report-app-page .progress-report-page .progress-report-header a {
  color: #102945;
}
#progress-report-app-page .progress-report-page .img-fluid {
  width: 120px;
  height: 85px;
  object-fit: cover;
  padding: 5px;
}

@font-face {
  font-family: "Aspekta";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Aspekta-400.otf");
}
@font-face {
  font-family: "Aspekta";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/Aspekta-700.otf");
}
.font-aspekta-400 {
  font-family: "Aspekta", sans-serif;
  font-weight: 400;
}

.font-aspekta-700 {
  font-family: "Aspekta", sans-serif;
  font-weight: 700;
}

.colourScheduleLightbox .attachmentDiv {
  width: 144px;
  border: 1px solid #d9d9d9;
  text-align: center;
}
.colourScheduleLightbox .attachmentDiv div {
  background-color: #f8f9fa;
  font-size: 12px;
}
.colourScheduleLightbox .attachmentDiv.image img {
  width: 100%;
  height: 76px;
  object-fit: cover;
  padding: 0px;
}
.colourScheduleLightbox .attachmentDiv.pdf {
  padding-top: 10px;
}
.colourScheduleLightbox .attachmentDiv.pdf div {
  margin-top: 10px;
}
.colourScheduleLightbox .attachmentDiv:hover {
  cursor: pointer;
  border: 1px solid #ababab;
}
.colourScheduleLightbox .attachmentDiv:hover div {
  background-color: #f3f4f5;
}

.colour-schedule-show {
  font-family: "Aspekta", sans-serif;
  font-size: 16px;
  color: #505151;
  background-color: #f8f9fa;
}
.colour-schedule-show.row {
  margin: 0px !important;
}
.colour-schedule-show .intro-div {
  background-color: #fff;
}
.colour-schedule-show .intro-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.colour-schedule-show .img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.colour-schedule-show .intro-left-div {
  margin-right: 40px;
}
@media screen and (max-width: 960px) {
  .colour-schedule-show .intro-left-div {
    margin-right: 16px !important;
  }
}
@media screen and (min-width: 1300px) {
  .colour-schedule-show .intro-left-div {
    margin-left: 100px !important;
  }
}
@media screen and (min-width: 1400px) {
  .colour-schedule-show .intro-left-div {
    margin-left: 100px !important;
  }
}
@media screen and (min-width: 1500px) {
  .colour-schedule-show .intro-left-div {
    margin-left: 150px !important;
  }
}
@media screen and (min-width: 2000px) {
  .colour-schedule-show .intro-left-div {
    margin-left: 200px !important;
  }
}
.colour-schedule-show .intro-table td {
  vertical-align: top;
}
.colour-schedule-show .proposal-div {
  background-color: #fff;
  max-width: 1200px;
  margin: auto;
}
.colour-schedule-show .overlay-requested-div {
  background-color: #fff;
  width: 100%;
  padding-top: 40px;
}
.colour-schedule-show .overlay-requested-div .overlay-requested-content {
  background-color: #f8f9fa;
  max-width: 1200px;
  margin: auto;
}
.colour-schedule-show .colourTable .area {
  width: 300px;
}
@media screen and (max-width: 767px) {
  .colour-schedule-show .colourTable .area {
    width: 240px;
  }
}
.colour-schedule-show .colourTable .option {
  width: 200px;
}
.colour-schedule-show .bb-gray {
  border-bottom: 1px solid #d9d9d9;
}
.colour-schedule-show .colour-proposal-header {
  display: flex;
}
@media screen and (max-width: 599px) {
  .colour-schedule-show .colour-proposal-header {
    display: none !important;
  }
}
.colour-schedule-show .colour-proposal-body {
  font-family: "Aspekta", sans-serif;
  font-weight: 400;
  color: #505151;
}
.colour-schedule-show .option-label {
  font-family: "Barlow", sans-serif;
  font-weight: 700;
  color: #707070;
  letter-spacing: 1px;
  margin-bottom: 5px;
}
@media screen and (min-width: 599px) {
  .colour-schedule-show .option-label.topic {
    display: none !important;
  }
}
.colour-schedule-show .option-line-item-title {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
}
@media screen and (max-width: 599px) {
  .colour-schedule-show .option-line-item-title {
    border-bottom: none;
  }
}
@media screen and (max-width: 599px) {
  .colour-schedule-show .one-option.col-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.colour-schedule-show .p-comment {
  font-size: 12px;
  font-family: "Aspekta", sans-serif;
  color: #707070 !important;
  padding-top: 5px;
}
.colour-schedule-show .el-radio-group label.main-label {
  font-size: 16px;
  font-weight: 700;
  color: #505151;
}
.colour-schedule-show .btn-submit {
  background-color: #1e242b;
  color: #fff;
  border-radius: 20px;
  width: 100%;
}
.colour-schedule-show .btn-submit:hover {
  color: #fff;
  font-weight: 700;
}
.colour-schedule-show .selectedColour {
  width: 72px;
  height: 72px;
  margin-right: 5px;
}
.colour-schedule-show .selectedColour img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.colour-schedule-show .selectedColourDiv {
  background-color: #edf3fd;
}
.colour-schedule-show .el-input__inner {
  border-radius: 0px;
}
.colour-schedule-show .el-radio__input.is-checked + .el-radio__label {
  color: #2d3748;
}
.colour-schedule-show .el-radio__label {
  font-size: 16px;
}
.colour-schedule-show .overlayDiv {
  cursor: pointer;
  color: #ababab;
  font-weight: 700;
}
.colour-schedule-show .overlayActive {
  font-weight: 700;
  border-bottom: 1px solid #1e242b;
  color: #1e242b;
}
.colour-schedule-show .termsOverflow {
  border: 1px solid #dcdfe6;
  border-radius: 5px;
  height: 280px;
  overflow-y: auto;
  padding: 1rem;
}
.colour-schedule-show h1,
.colour-schedule-show h2,
.colour-schedule-show h3 {
  font-family: "Barlow", sans-serif;
  color: #505151;
}
.colour-schedule-show h1 {
  font-size: 48px;
}
.colour-schedule-show h2 {
  font-size: 44px;
  font-weight: 500;
  line-height: 52px;
}
.colour-schedule-show h3 {
  font-size: 30px;
  font-weight: 500;
  line-height: 36px;
  color: #505151;
}
.colour-schedule-show .plabel {
  font-size: 14px;
  font-family: "Barlow", sans-serif;
  font-weight: 700;
  color: #707070;
  letter-spacing: 1px;
}
.colour-schedule-show .table thead th,
.colour-schedule-show .table td {
  border-top: none;
  border-bottom: 1px solid #d9d9d9;
}
.colour-schedule-show .color-gray {
  color: #505151;
}
.colour-schedule-show th {
  font-family: "Barlow", sans-serif;
  font-weight: 700;
  color: #707070;
  letter-spacing: 1px;
}
.colour-schedule-show .square-tile img {
  width: 40px;
  height: 40px;
  object-fit: cover;
}
.colour-schedule-show .square-text {
  color: #505151;
  font-size: 14px;
  font-weight: 400;
}
.colour-schedule-show .square-text span {
  font-size: 12px;
  color: #707070;
}
.colour-schedule-show .consultant-avatar {
  width: 56px;
  height: 56px;
  border-radius: 50%;
}
.colour-schedule-show .el-radio {
  font-family: "Aspekta", sans-serif;
  font-weight: 400;
}
.colour-schedule-show .selectRadioGroup {
  display: flex;
}
@media screen and (max-width: 599px) {
  .colour-schedule-show .selectRadioGroup {
    display: inline-block;
  }
  .colour-schedule-show .selectRadioGroup .bb-gray {
    border-bottom: none;
  }
}
.colour-schedule-show .imgicon {
  width: 144px;
}
.colour-schedule-show .project_overlay {
  background-color: #f8f7f5;
}
.colour-schedule-show .project_overlay .price-text {
  font-family: "Barlow", sans-serif;
  font-weight: 700;
  font-size: 28px;
}
.colour-schedule-show .project_overlay .price-gst {
  font-family: "Aspekta", sans-serif;
  font-size: 12px;
  font-weight: 400;
}

.colour-schedule-card-page {
  margin: 0px 50px;
}
.colour-schedule-card-page .text-ellipsis {
  color: #1976d2;
}

.colour-schedule-form {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
}
.colour-schedule-form h1 {
  font-size: 24px;
  font-family: "lateral", sans-serif;
  font-weight: 700;
  color: #102945;
}
.colour-schedule-form h2 {
  color: #e53e3e;
  font-family: "lateral", sans-serif;
  font-weight: 400;
  font-size: 20px;
}
.colour-schedule-form h3 {
  font-size: 24px;
  font-family: "lateral", sans-serif;
  font-weight: 400;
  color: #102945;
}
.colour-schedule-form p {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #102945;
}
.colour-schedule-form .line-item-table th,
.colour-schedule-form .line-item-table td {
  border: 1px solid #d9d9d9;
}
.colour-schedule-form .line-item-table th {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: #102945;
}
.colour-schedule-form .btn {
  font-family: "lateral", sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  padding: 10px;
}
.colour-schedule-form .btn.btn-preview {
  background: #51a6d3;
}
.colour-schedule-form .btn.btn-send {
  background: #102945;
}
.colour-schedule-form .el-input input {
  font-size: 14px !important;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #1e242b;
  padding: 15px 20px 15px 5px;
}
.colour-schedule-form .colour-box {
  width: 32px;
  height: 32px;
  margin-right: 5px;
  background-image: url("/img/interiors/swatch_default.png");
  position: relative;
}
.colour-schedule-form .colour-trash {
  background-color: #d9d9d9;
  width: 32px;
  height: 32px;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 0;
}
.colour-schedule-form .colour-trash svg {
  color: #fff;
  margin-top: 35%;
}
.colour-schedule-form .comment.form-control {
  font-size: 14px !important;
  background-color: #f8f9fa;
  border: 1px solid #f8f9fa;
}
.colour-schedule-form .comment::placeholder {
  color: #ababab;
}
.colour-schedule-form .secondUpload .el-upload {
  display: none;
}
.colour-schedule-form .el-upload-dragger {
  width: 136px;
  height: 136px;
}
.colour-schedule-form .el-upload--picture-card {
  width: 136px;
  height: 136px;
}
.colour-schedule-form .colourScheduleLightbox .attachmentDiv {
  width: 160px;
  height: 135px;
  border: 1px solid #d9d9d9;
  text-align: center;
  border-radius: 8px;
}
.colour-schedule-form .colourScheduleLightbox .attachmentDiv.image img {
  height: 115px;
}
.colour-schedule-form .colourScheduleLightbox .attachmentDiv div {
  border-radius: 0 0 8px 8px;
}
.colour-schedule-form .colourScheduleLightbox .attachmentDiv.pdf img {
  margin-top: 20px;
  margin-bottom: 19px;
}
.colour-schedule-form .colourScheduleLightbox .colour-trash {
  position: absolute;
  border-radius: 8px;
  background-color: #000;
  opacity: 0.6;
}
.colour-schedule-form .upload-loading {
  width: 160px;
  height: 135px;
  border: 1px solid #d9d9d9;
  text-align: center;
  border-radius: 8px;
}
.colour-schedule-form .upload-loading svg {
  margin-top: 30%;
  color: #1976d2;
}

.accepted_popup h2 {
  font-size: 44px;
  font-weight: 500;
  line-height: 52px;
  font-family: "Barlow", sans-serif;
  color: #505151;
}
.accepted_popup .accepted_body {
  padding: 10px;
}
.accepted_popup p {
  font-family: "Aspekta", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #505151;
}
.accepted_popup .img-fluid {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.btn-colour-schedule {
  background-color: #1e242b;
  color: #fff;
  padding: 14px 40px;
  border-radius: 56px;
  font-family: "Aspekta", sans-serif;
  font-weight: 700;
  font-size: 16px;
  border: 0;
  box-shadow: none !important;
}
.btn-colour-schedule:hover {
  background-color: #1e242b;
  color: #fff;
}
.btn-colour-schedule.disabled {
  background-color: #edf3fd;
  color: #505151;
}

.colour-icon {
  width: 32px;
  height: 32px;
  object-fit: cover;
}