@import 'fonts';
@import 'backgrounds';

.full-bg-img {
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
}

.cursor-pointer {
    cursor: pointer;
}