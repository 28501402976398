.feedback-face-btn-no-border {
  cursor: pointer;
  position: relative;
  height: 95px;
  width: 95px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 5px;
  padding: 20px;
  color: $roch-placeholder;
  
  .far {
    color: $roch-placeholder !important;
  }

  @media (max-width: 575.98px) {
    padding: 5px;

    .fa-4x {
      font-size: 3em;
    }
  }

  @media (max-width: 1199.98px) {
    margin-bottom: 15px;
    width: 100%;
    height: 100%;
  }
}

.give-feedback-wrap {
  textarea {
    border-radius: 5px;
  }
}

%feedback-face-settings {
  height: 50px;
  width : 50px;
}

%face-pseudo-settings {
  height     : 50px;
  font-size  : 50px;
  line-height: 50px;
  position   : absolute;
  width      : 50px;
  transition: 0.3s all ease-in-out;
}

%face-pseudo-smile-settings {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  content    : '\f118';
  color      : $card-complete;
}

%face-pseudo-meh-settings {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  content    : '\f11a';
  color      : $card-contractor-enter-at;
}

%face-pseudo-frown-settings {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  content    : '\f119';
  color      : $roch-dark-red;
}

.active-face-smile .feedback-face-smile {
  @extend %feedback-face-settings;

  &::after {
    @extend %face-pseudo-settings;
    @extend %face-pseudo-smile-settings;
  }
}

.feedback-face-smile {
  @extend %feedback-face-settings;

  &::after {
    @extend %face-pseudo-settings;
    font-family: 'Font Awesome 5 Pro';
    font-weight: 400;
    content    : '\f118';
  }

  &:hover {
    &::after {
      @extend %face-pseudo-smile-settings;
    }
  }
}


.active-face-meh .feedback-face-meh {
  @extend %feedback-face-settings;

  &::after {
    @extend %face-pseudo-settings;
    @extend %face-pseudo-meh-settings;
  }
}

.feedback-face-meh {
  @extend %feedback-face-settings;

  &::after {
    @extend %face-pseudo-settings;
    font-family: 'Font Awesome 5 Pro';
    font-weight: 400;
    content    : '\f11a';
  }

  &:hover {
    &::after {
      @extend %face-pseudo-meh-settings;
    }
  }
}


.active-face-frown .feedback-face-frown {
  @extend %feedback-face-settings;

  &::after {
    @extend %face-pseudo-settings;
    @extend %face-pseudo-frown-settings;
  }
}

.feedback-face-frown {
  @extend %feedback-face-settings;

  &::after {
    @extend %face-pseudo-settings;
    font-family: 'Font Awesome 5 Pro';
    font-weight: 400;
    content    : '\f119';
  }

  &:hover {
    &::after {
      @extend %face-pseudo-frown-settings;
    }
  }
}