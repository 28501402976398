// 8-11-21
// There is a bug on this page relating to the scroll bar display
// Seems like it's attached to the html tag, so we'll work around
// it in case we cause unknown damage elsewhere. Appears to be 
// attached to a mystery css reset

.subjob-card {
    .col-12 {
        @media (max-width: 1263px) {
            padding-bottom: 0 !important;
        }
    }
}

.subjob-titles {
    display: flex !important;

    @media (max-width: 1263px) {
        display: none !important;
    }
}

.radio-group-wrap {
    min-width: 180px;

    @media (max-width: 750px) {
        margin-top: 15px;
        width: 100%;

        label {
            width: 50%;
        }
    }

    label {
        min-width: 90px;

        span {
            width: 100%;
        }
    }

    .el-radio-button {
        color: $roch-border-dash-blue;

        &:first-child .el-radio-button__inner {
            border-radius: 5px 0 0 5px;
        }

        &:last-child .el-radio-button__inner {
            border-radius: 0 5px 5px 0;
        }
    }

    .el-radio-button__inner {
        color: $roch-border-dash-blue;
        border-color: $roch-border-dash-blue !important;

        &.is-active {
            color: #fff;
        }
    }
}

.subjob-wrap {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}


.subjob-share-modal {
    .modal-body {
        padding-top: 0;
    }
}

.btn-add-email {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
    min-height: 38px !important;
    color: $roch-dark-blue !important;
    font-size: 14px !important;
    background-color: $tag-blue-light !important;
    border: 1px solid $tag-border-blue !important;
    border-radius: 5px !important;
}