.bg-white {
  background-color: #fff;
}

.bg-body {
  background-color: #f7f8f9;
}

.bg-dark-blue {
  background-color: $roch-dark-blue;
}

.bg-com-blue {
  background-color: $roch-com-blue;
}

.full-bg {
  background-size: cover;
  background-position: 50%;
}

.bg-chat-mine {
  background-color: #e8edf4;
}

.bg-chat-yours {
  background-color: #f8f8f8;
}

.bg-secondary-50 {
  background-color: $secondary-50;
}
