h1 {
	font-size: 2.1875rem;

	@media (max-width: 575.98px) {
		font-size: 28px;
	}
}


// Font colors
.text-red,
.red-text {
	color:red;

	strong {
		color:red;
	}
}

.text-black,
.font-black {
	color: #000;

	strong {
		color: #000;
	}
}

.text-dark-blue {
  color: $roch-dark-blue;

  strong {
	color: $roch-dark-blue;
  }
}

.text-com-blue {
	color: $roch-com-blue;

	strong {
		color: $roch-com-blue;
	}
}

.color-placeholder {
	color: $placeholder-grey;
}

.color-dash-blue {
	color: $roch-border-dash-blue;
}

// Font weights
.p-bold,
.fw-700 {
	font-weight:bold;
}

.p-thin,
.fw-400 {
	font-weight:400;
}


// Font sizes
.font-12 {
	font-size: 12px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-16 {
	font-size: 16px !important;
}  

.font-18 {
  font-size: 18px !important;
}

.font-20 {
  font-size: 20px !important;
}

.p-small {
	font-size: 0.95em;
}

.hover-underline:hover {
	text-decoration: underline;
}

// set to var
.open-sans {
	font-family: 'Open Sans', sans-serif;;
}

// set to var
.desc-text {
	color: #677781;
}